import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  slideInFromBottom,
  containerShowOnLanding,
} from '../../utils/animations';

import styles from './contact-grid.module.scss';

const Grid = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-100px',
  });

  return (
    <motion.section
      className={styles.container}
      ref={ref}
      animate={inView ? 'visible' : 'hidden'}
      initial="hidden"
      variants={containerShowOnLanding}
    >
      <motion.div className={styles.block} variants={slideInFromBottom}>
        <div className={styles.pretitle}>Teléfono</div>
        <h2 className={styles.title}>
          Hagamos realidad tu nuevo projecto juntos.
        </h2>
        <p className={styles.description}>
          <a href="tel:0034667513400">+34 667 513 400</a>
        </p>
      </motion.div>

      <motion.div className={styles.block} variants={slideInFromBottom}>
        <div className={styles.pretitle}>Email</div>
        <h2 className={styles.title}>
          Siempre disponibles para cualquier consulta.
        </h2>
        <p className={styles.description}>
          <a href="mailto:contacto@jrweb.es">contacto@jrweb.es</a>
        </p>
      </motion.div>

      {/* <motion.div className={styles.block} variants={slideInFromBottom}>
        <div className={styles.pretitle}>Dirección</div>
        <h2 className={styles.title}>Nuestra oficina.</h2>
        <p className={styles.description}>
          Nombre de la calle, numero
          <br />
          Localidad, c.p., España.
        </p>
      </motion.div> */}
    </motion.section>
  );
};

export default Grid;
