import React from 'react';

import SEO from '../components/molecules/seo';
import HeroBanner from '../components/molecules/hero-banner-section';
import Grid from '../components/organisms/contact-grid';

const Contacto = () => (
  <>
    <SEO
      title="Llámanos al 667 513 400. Email: info@jrweb.es | JR Web"
      description="Ponte en contacto para cualquier proyecto digital. Te ayudamos con el diseño o desarrollo de tu web en Tenerife."
    />
    <HeroBanner
      isLeftAligned
      content={{
        title: 'Contacta con nosotros',
        description:
          'Cuéntanos tus planes y te ayudaremos a poner en marcha tu proyecto y alcanzar tus objetivos.',
      }}
    />
    <Grid />
  </>
);

export default Contacto;
