import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import {
  containerShowOnLanding,
  itemShowOnLanding,
} from '../../utils/animations';

import CTAButton from '../atoms/cta-button';
import styles from './hero-banner-section.module.scss';

const HeroBanner = ({ isLeftAligned, hasDarkBackground, content }) => {
  let classes = '';
  if (isLeftAligned) {
    classes = styles.containerLeftAligned;
  } else if (hasDarkBackground) {
    classes = styles.containerDarkBg;
  } else {
    classes = styles.container;
  }

  return (
    <motion.section
      className={classes}
      animate="visible"
      initial="hidden"
      variants={containerShowOnLanding}
    >
      {content.title && (
        <motion.h1 className={styles.title} variants={itemShowOnLanding}>
          {content.title}
        </motion.h1>
      )}
      {content.pretitle && (
        <motion.h2 className={styles.pretitle} variants={itemShowOnLanding}>
          {content.pretitle}
        </motion.h2>
      )}
      {content.description && (
        <motion.p className={styles.description} variants={itemShowOnLanding}>
          {content.description}
        </motion.p>
      )}

      {content.button && (
        <motion.div className={styles.button} variants={itemShowOnLanding}>
          <CTAButton to={content.button.to} text={content.button.text} />
        </motion.div>
      )}
    </motion.section>
  );
};

HeroBanner.propTypes = {
  isLeftAligned: PropTypes.bool,
  hasDarkBackground: PropTypes.bool,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    pretitle: PropTypes.string,
    description: PropTypes.string.isRequired,
    button: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

HeroBanner.defaultProps = {
  isLeftAligned: false,
  hasDarkBackground: false,
};

export default HeroBanner;
